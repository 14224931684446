<template>
  <div>
    <v-dialog v-model="dialog" width="800">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="title === 'Crear'"
          block
          small
          depressed
          color="primary"
          v-bind="attrs"
          v-on="on"
        >
          Nuevo
        </v-btn>
        <v-btn
          v-else
          text
          small
          icon
          v-bind="attrs"
          v-on="on"
          @click="setForm()"
        >
          <v-icon small>mdi-square-edit-outline</v-icon>
        </v-btn>
      </template>

      <v-card class="pa-sm-10 pa-5">
        <div class="text-right">
          <v-icon @click="dialog = false" style="cursor: pointer">
            mdi-close
          </v-icon>
        </div>
        <v-form
          @submit.prevent="
            title === 'Crear' ? createSecretary() : editSecretary()
          "
          v-model="validation"
          ref="form"
        >
          <v-container>
            <Title :title="title + ' usuario'" class="mb-8" />
            <v-row>
              <v-col>
                <v-text-field
                  v-model="form.firstName"
                  type="text"
                  label="Nombres"
                  :rules="rules.default"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="form.lastName"
                  type="text"
                  label="Apellidos"
                  :rules="rules.default"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="form.phone"
                  type="number"
                  label="Teléfono"
                  @wheel="$event.target.blur()"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="form.email"
                  type="e-mail"
                  label="Correo electrónico"
                  :rules="rules.email"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-if="title === 'Crear'"
                  v-model="form.password"
                  label="Contraseña"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPass ? 'text' : 'password'"
                  @click:append="showPass = !showPass"
                  :rules="rules.password"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions class="text--right">
            <v-btn
              small
              depressed
              color="primary"
              class="pa-5"
              type="submit"
              :disabled="!validation"
              :loading="loading"
            >
              {{ title }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
import Title from "../Title.vue";

export default {
  name: "SecretaryForm",
  components: {
    Title
  },
  props: ["title", "secretary"],
  data() {
    return {
      dialog: false,
      form: {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        password: "",
        role: "secretary",
      },
      validation: true,
      rules: {
        default: [
          (v) => !!v || "Campo obligatorio",
          (v) => (v && v.length <= 50) || "Debe tener menos de 50 caracteres",
        ],
        email: [(v) => /.+@.+\..+/.test(v) || "No es un correo valido"],
        password: [
          (v) => !!v || "Campo obligatorio",
          (v) => (v && v.length >= 8) || "Debe contener al menos 8 caracteres",
        ],
        phone: [
          (v) => !!v || "Campo obligatorio",
          (v) =>
            (v && v.length == 10) ||
            "Debe contener 10 dígitos (ejemplo: 8093451290)",
        ],
      },
      loading: false,
      showPass: false,
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    ...mapMutations(["setAlert", "pushSecretary", "updateSecretary"]),
    setForm() {
      Object.assign(this.form, this.secretary.user);
    },
    async createSecretary() {
      try {
        this.loading = true;
        this.form.doctorID = this.currentUser.doctor.id;
        const res = await axios.post("/api/secretary", this.form);
        const { secretary } = res.data;
        this.pushSecretary(secretary);
        const alert = {
          show: true,
          timeout: 4000,
          color: "success",
          icon: "mdi-check-circle",
          text: "Usuario creado correctamente",
        };
        this.setAlert(alert);
        this.dialog = false;
        this.$refs.form.reset();
      } catch (error) {
        console.log(error);
        const errorAlert = {
          show: true,
          color: "error",
          timeout: 4000,
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        };
        this.setAlert(errorAlert);
      }
      this.loading = false;
    },
    async editSecretary() {
      try {
        this.loading = true;
        const data = {
          id: this.secretary.id,
          doctor: {
            id: this.currentUser.doctor.id,
          },
          user: {
            ...this.form,
          },
        };
        const res = await axios.put(`/api/secretary/${data.id}`, data);
        const { secretary } = res.data;
        this.updateSecretary(secretary);
        const alert = {
          show: true,
          color: "dark_primary",
          timeout: 4000,
          icon: "mdi-check-circle",
          text: "Usuario actualizado correctamente",
        };
        this.setAlert(alert);
        this.dialog = false;
        this.$refs.form.reset();
      } catch (error) {
        console.log(error);
        const errorAlert = {
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        };
        this.setAlert(errorAlert);
      }
      this.loading = false;
    },
  },
};
</script>
